import React, { useState } from 'react';
import { trimAndJoinString } from './Showevent';

const EditableInput = ({ label, value, onChange,error }) => {


  const handleInputChange = (event) => {
  

    onChange(event.target.id, event.target.value);// Update the parent component with the updated new value  
  };

  return (
   <div>
     <div className="items-center space-x-2 mb-2 bg-white rounded p-4 shadow">
    <label
      htmlFor={label}
      className="block text-xs font-bold font-basic leading-[21.98px] align-baseline tracking-[0] text-gray-400 text-md"
    >
      {trimAndJoinString(label)}:
    </label>
    <input
      type="text"
      value={value}
      id={label}
      onChange={handleInputChange}
      className=" px-2 py-1 focus:outline-none focus:border-blue-500 w-3/4 text-sm"
    />
                 {       error && (<p className="text-red-500 font-normal text-sm font-sans font-normal ">
{error}
</p>)
               }
  </div>
  
   </div>
  );
};

export default EditableInput;
