function checkdiff(event) {
  const currentDate = new Date();
  const date1 = new Date(event);
  const diffInMilliseconds = Math.abs(currentDate - date1);
  const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
  return diffInDays;
}
function isWeekend(event) {
  const date = new Date(event);
  const dayOfWeek = date.getDay();

  // Check if the day of the week is Saturday (6) or Sunday (0)
  if (dayOfWeek === 6 || dayOfWeek === 0) {
    return "Weekend"; // Date falls on a weekend
  } else {
    return "Weekday"; // Date does not fall on a weekend
  }
}

const ValidateField = (
  fieldName,
  value,
  event,
  Location,
  Expected_internal_Team_Members,
  Expected_Number_of_Student_Attendees,
  Expected_Number_of_External_Guests,
  EventRegistereddate
) => {
  let errorMessage = "";
  const add =
    parseInt(Expected_internal_Team_Members) +
    parseInt(Expected_Number_of_Student_Attendees) +
    parseInt(Expected_Number_of_External_Guests);
  // console.log("Internal hai hm",fieldName,value);


  if (fieldName === "Proposed_Event_Date") {
    // Validate the Originator_Team field
    // Example validation:

    const currentDate = EventRegistereddate ? EventRegistereddate : new Date();
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 14);
    const enteredDate = new Date(value);

    if (enteredDate < futureDate) {
      errorMessage = "The proposed event date must be provided at least 14 days before the event!";
    }
  } else if (fieldName == "Total_Number_of_Attendees") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 2)
      errorMessage = "The expected total number of attendees has to be prescribed at least 2 days before the event!";
  } else if (fieldName == "Internal_Guests") {
   
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "The expected number of internal members has to be prescribed at least 5 days prior to the event date!";
    }
  }
  else if(fieldName==="Auditorium")
  {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 15) {
      errorMessage = "Requirements for the auditorium must be submitted at least 15 days before the event!";
    }
  }

  else if (fieldName == "External_Guests") {
 
    const diffInDays = checkdiff(event);
    if (diffInDays <= 3) {
    
      errorMessage = "The expected number of external guests has to be prescribed at least 3 days prior to the event date!";
    }
  } else if (fieldName === "UG") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
   
      errorMessage = "The expected number of UG student attendees has to be provided at least 5 days before the even!";
    }
  } else if (fieldName == "PGP") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "The expected number of PGP student attendees has to be provided at least 5 days before the even!";
    }
  } else if (fieldName == "Rise") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "The expected number of Rise student attendees has to be provided at least 5 days before the even!";
    }
  } else if (fieldName == "MasterCamps") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "The expected number of MasterCamps student attendees has to be provided at least 5 days before the even!";
    }
  } else if (fieldName == "Equipment") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 4) {
      errorMessage = "Equipment requirements have to be shared at least 4 days before the event!";
    }
  }
  
  else if (fieldName == "Cutlery") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 15) {
      errorMessage = "Cutlery requirements have to be shared at least 15 days before the event!";
    }
  }

  else if (fieldName == "Specific_Food_Menu") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 10) {
      errorMessage = "Specific food menu requirements have to be shared at least 10 days before the event!";
    }
  }

  else if (fieldName =="Dietary_Requirement") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "Any dietary requirements have to be shared at least 5 days before the event!";
    }
  }
  


  else if (fieldName == "Tech_Support") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 3) {
      errorMessage = "Tech support requirements have to be shared at least 3 days before the event!";
    }
  } else if (fieldName == "Tech_Support") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 4) {
      errorMessage = "Entered date should be 4 days prior to the event date!";
    }
  } else if (fieldName == "Seating_Arrangement") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 2) {
      errorMessage = "Seating requirements have to be provided at least 2 days before the event!";
    }
  } else if (fieldName == "VIP_Seating") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 2) {
      errorMessage = "VIP seating requirements have to be provided at least 2 days before the event!";
    }
  } else if (fieldName == "Outsourced_Seating") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 10) {
      errorMessage = "Outsourced seating requirements have to be provided at least 10 days before the event!";
    }
  } else if (fieldName == "Special_Accommodation") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "Special accommodation requirements have to be shared at least 5 days before the event!";
    }
  } else if (fieldName === "Medical_Support") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 7) {
      errorMessage = "Medical support for guests has to be informed at least 7 days before the event!";
    }
  } else if (fieldName == "Stationary_Requirements") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 3) {
      errorMessage = "Stationery requirements have to be provided at least 3 days prior to the event!";
    }
  } else if (fieldName == "Decoration_and_Theme") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 8) {
      errorMessage = "Decoration and Theme requirements have to be shared at least 8 days before the event!";
    }
  } else if (fieldName == "Special_Guests_or_Performers") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 8) {
      errorMessage = "Special Guests or Performer requirements needs to be shared at least 8 days prior to the event date!";
    }
  } else if (fieldName === "PhotographyandVideography") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 7) {
      errorMessage = "Photography/ Videography requirements have to be shared at least 7 days before the event!";
    }
  } else if (fieldName == "Goal") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 7) {
      errorMessage = " Standee requirements have to be provided at least 7 days before the event!";
    }
  } else if (fieldName == "Parking_Arrangements") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 4) {
      errorMessage = "Parking Requirements have to be provided at least 4 days before the event!";
    }
  }
  else if (fieldName == "New_Goodies") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 30) {
      errorMessage = "New goodies for gifting requirements have to be shared at least 30 days before the event!";
    }
  }

  else if (fieldName == "Customized_Gifting") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 15) {
      errorMessage = "Specially customized gifting requirements have to be shared at least 15 days before the event!";
    }
  }
  else if (fieldName == "Security_Staff") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "Security Staff requirements have to be shared at least 5 days before the event!";
    }
  } else if (fieldName == "Transportation_Services") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "Transportation Requirements have to be shared at least 5 days before the even!";
    }
  } else if (fieldName == "Vendor") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 15) {
      errorMessage = "Vendor requirements have to be provided at least 15 days before the event!";
    }
  } else if (fieldName == "DLF_Food_Court") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "DLF Food Court availability requirements have to be shared at least 5 days before the event!";
    }
  } else if (fieldName === "In-house_Caterers") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 5) {
      errorMessage = "Requirement for In-house Caterers has to be shared at least 5 days before the event!";
    }
  } else if (fieldName === "Schedule") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 8) {
      errorMessage = "The schedule has to be shared at least 8 days before the event!";
    }
  } else if (fieldName == "Other_Logistics") {
    const diffInDays = checkdiff(event);
    if (diffInDays <= 7) {
      errorMessage = "Other Logistical requirements have to be shared at least 7 days before the event!";
    }
  }

  //    else if (fieldName === "Any_limitations_on_Photography_Videography") {
  //     const diffInDays = checkdiff(event);

  //     if (diffInDays <= 5) {
  //       errorMessage =
  //         "Value can only be entered at least 5 days before the event!";
  //     }
  //   }
  return errorMessage;
};
export default ValidateField;
