import logo from "./logo.svg";
import "./App.css";
import { Eventform } from "./component/Eventform";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { Provider as AlertProvider, positions, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Showevent } from "./component/Showevent";
import Table from "./component/Table";
import { useEffect, useState } from "react";
import axios from "axios";
import EditEvent from "./component/EditEvent";

function App() {
  const [Data, setData] = useState();
  const [error, setError] = useState();
  const [eventData, setEditEvent] = useState();

  const [handleEventData, setHandleEventDate] = useState();
  const handleChangeroute = (Id) => {
    const _id = Id;

    setEditEvent(_id);

    // setEventId(id);
  };
  const handleditevent = (ID) => {
    setHandleEventDate(ID);
  };

  useEffect(() => {
    if(eventData)
    {
  axios
      .get(
        `https://api-v2.mastersunion.in/api/v1/getsingleevent?id=${eventData}`
      )
      .then((response) => {
        // Handle the response data
        setData(response.data.user);
      })
      .catch((error) => {
        // Handle any errors
        setError(error.data);
      });
    }
  
  }, [eventData]);
 

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    transition: transitions.SCALE,
  };
  return (
    // basename="https://new-admin.mastersunion.in/event-manage"
    <div>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <Routes>
            <Route
              path="/event-manage"
              element={
                <Table
                  handleChangeroute={handleChangeroute}
                  handleditevent={handleditevent}
                />
              }
            />
            <Route
              path="/event-manage/eventFullview"
              element={<Showevent Data={Data} />}
            />
            <Route path="/event-manage/event-form" element={<Eventform />} />
            <Route
              path="/event-manage/event-edit"
              element={<EditEvent Editdata={eventData} />}
            />
          </Routes>
        </AlertProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
