import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
const Table = ({ handleChangeroute, handleditevent }) => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [DeleteEventsuccess, setDeleteEventSuccess] = useState();
  const [DeleteEventerror, setDeleteEventerror] = useState();
  const [cookies, setCookie] = useCookies();
  const [query, setQuery] = useState("");
  const alert = useAlert();

  useEffect(() => {
    axios
      .get("https://api-v2.mastersunion.in/api/v1/getevent")
      .then((response) => {
        // Handle the response data

        setData(response.data.user);
      })
      .catch((error) => {
        // Handle any errors

        setData(error.data);
      });
  }, []);

  const handlenavigate = (id) => {
    handleChangeroute(id);
    navigate("/event-manage/eventFullview");
  };
  // const handleEditEvent = (id) => {
  //  handleChangeroute(id);
  //   navigate("/event-manage/event-edit");
  // };

  const handleDelete = (Id) => {
    const _id = Id;

    axios
      .delete(`https://api-v2.mastersunion.in/api/v1/event/delete?id=${_id}`)
      .then((response) => {
        // Handle the response data
        setDeleteEventSuccess(response.data);
      })
      .catch((error) => {
        // Handle any errors
        setDeleteEventerror(error.data);
      });
  };
  useEffect(() => {
    if (DeleteEventsuccess) {
      alert.success("Delete Event Successfully");
      window.location.reload();
    }
    if (DeleteEventerror) alert.error("Failed to delete Event.");
  }, [DeleteEventsuccess]);
    if(!cookies?.loginData)
    {
  navigate("/login");
    }
  const initialUserArray = data || [];

  // Initialize sortedUser state with initialUserArray
  const [sortedUser, setSortedUser] = useState([...initialUserArray]);

  const sortByEventDateDescending = () => {
    try {
      // Sort initialUserArray and update sortedUser state
      const sorted = [...initialUserArray].sort((a, b) => {
        const dateA = new Date(a.Proposed_Event_Date);
        const dateB = new Date(b.Proposed_Event_Date);
        return dateB - dateA;
      });
      setSortedUser(sorted);
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setQuery(e.target.value.toLowerCase())
    filterArrayBySearchQuery(sortedUser, query)
  }
  function filterArrayBySearchQuery(sortedUser, searchQuery) {
    if (!searchQuery) {
      return sortedUser; // Return the original array if searchQuery is empty
    }

    const query = searchQuery.toLowerCase();

    return sortedUser.filter(item => {

      // Convert the properties you want to search into lowercase as well
      const searchableProperty1 = item.Event_Title?.toLowerCase();
      const searchableProperty2 = item.Event_Description?.toLowerCase();
      const searchableProperty3 = item?.Originating_Department?.toLowerCase();
      const searchableProperty4 = item?.Location.map((item) => { return (item?.value) });
      // Return true if any of the searchable properties contain the search query
      return (
        searchableProperty1?.includes(query) ||
        searchableProperty2?.includes(query) || searchableProperty3?.includes(query) || searchableProperty4?.includes(query)
      );
    });
  }

  useEffect(() => {
    sortByEventDateDescending();
  }, [data])
  return (
    <div className='className="overflow-x-auto "'>
      {/* <div className=" flex flex-inline gap-8 pb-3 pt-3 pl-4">
        {" "}
        <button
          onClick={() => {
            navigate("/event-manage/event-form");
          }}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded "
        >
          + Create Event
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            className="border rounded-md p-2 " style={
              { width: "350px" }
            }
            placeholder="Search Events by event title , event description "
            value={query}
            onChange={(e) => { handleChange(e) }}
          />
          <div className="">
            <button
              className="bg-blue-500 text-white p-2 rounded-md"
              onClick={""}
            >
              Search
            </button>
          </div>
        </div>
      </div> */}
      <div className="flex flex-inline gap-8 pb-3 pt-3 pl-4">
  <button
    onClick={() => {
      navigate("/event-manage/event-form");
    }}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
  >
    + Create Event
  </button>
  <div className="flex items-center space-x-2">
    <input
      type="text"
      className="border rounded-md p-2"
      style={{ width: "350px" }}
      placeholder="Search Events by event title, event description"
      value={query}
      onChange={(e) => handleChange(e)}
    />
  </div>
  <div className="ml-auto"> {/* Aligns its children to the right */}
    <a
      className="bg-blue-500 text-white p-2 rounded-md mr-4"
href="https://mastersunion481.outgrow.us/Copy-of-Copy-of-mastersunion481-518-2-1"
    >
      Share Feedback
    </a>
  </div>
</div>

      <table className="min-w-full bg-white border border-gray-300 p-4 ">
        <thead>
          <tr>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Event Title
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider w-36">
              Event Date
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider w-44">
              Event Start Time
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Event Location
            </th>
            <th className="ppy-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Actions
            </th>
            {/* Add more table headers as needed */}
          </tr>
        </thead>
        <tbody>
          {filterArrayBySearchQuery(sortedUser, query)?.map((item) => {

            return (
              <tr key={""} className="even:bg-gray-100">
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Event_Title}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Proposed_Event_Date}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Proposed_Event_Start_Time}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Location[0]?.value}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {
                    <div className="flex flex-inline w-48 gap-2">
                      <button
                        onClick={() => {
                          handleDelete(item._id);
                        }}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                      >
                        delete
                      </button>{" "}
                      <button
                        onClick={(e) => {
                          handlenavigate(item._id);
                        }}
                        className="animate-pulse even:bg-gray-200 inline-block p-2 rounded hover:bg-gray-300"
                      >
                        {" "}
                        view full
                      </button>{" "}
                      {/* <button
                        onClick={(e) => {
                          handleEditEvent(item._id);
                        }}
                        className="animate-pulse even:bg-gray-200 inline-block p-2 rounded-full hover:bg-gray-300"
                      >
                        {" "}
                        Edit Event
                      </button> */}
                    </div>
                  }
                </td>
                {/* Add more table cells based on the data object */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
