import { useEffect, useState } from "react";
import {  eventsOptions } from "./Formfield";

import axios from "axios";
import { useAlert } from "react-alert";
import Plusinput from "./Plusinput";

import { useNavigate } from "react-router-dom";
import ValidateField from "./ValidateField";
const fields = [];
const extrafields = eventsOptions;

let fieldsState = {};
let extraState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));
const createExtraStateObject = (options) => {
  options.forEach((item) => {
    if (item.id) {
      extraState[item.id] = '';
    }

    if (item.options && item.options.length > 0) {
      item.options.forEach((option) => {
       
        if (option.id) {
          extraState[option.id] = '';
        }

        if (option.option && option.option.length > 0) {
          option.option.forEach((nestedOption) => {
         
            if (nestedOption.id) {
              extraState[nestedOption.id] = '';
             

              // Handling nested options inside UG, PGP, and RISE
              if (nestedOption.option && nestedOption.option.length > 0) {
              
                nestedOption.option.forEach((nestedNestedOption) => {
                  

                  if (nestedNestedOption.id) {
                    extraState[nestedNestedOption.id] = '';
                  }
                });
              }
            }
          });
        }
      });
    }
  });

  return extraState;
};

 extraState = createExtraStateObject(eventsOptions);

// Output the extrastate object

export const Eventform = () => {
  const [loginState, setLoginState] = useState(fieldsState);
  const [otherValue, setOtherValue] = useState(extraState);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const alert = useAlert();
  const [formErrors, setFormErrors] = useState({});
  const [state,setState]=useState({
    Type_of_Event:[],
    Attendees :[],
    Internal:[],
    Seating:[],
    Special_Requirement:[],
    // PhotographyandVideography:[],
    LOGISTICS:[],
    Food_Category:[],
    Tier:"",
    In_house_Catering:[],
    Special_Gifting:[],
    Location:[],
    Student:[],
    Members:[],
    Standees:[],
    Budget:"",
    Approval_Required:"",
    Event_Day_Type:"",
    Gift_Requirement_for_Guest:"",
    Catering_Quality:"",
    Meals_required:[],
    Sections_open_for:[],
    Equipment_Technology:[]

  })

  const handledropdowndata=(name,value)=>{
    
    setState({...state , [name]:value})
   
  }

  // const handleChange = (e) => {
  //   setLoginState({ ...loginState, [e.target.id]: e.target.value });

  //   const errorMessage = ValidateField(e.target.id, e.target.value,loginState?.Proposed_Event_Date,loginState.Event_Location , loginState?.Expected_internal_Team_Members,loginState?.Expected_Number_of_Student_Attendees,loginState?.Expected_Number_of_External_Guests);
   
   
  //   setFormErrors({
  //     ...formErrors,
  //     [e.target.id]: errorMessage,
  //   });
  // };

  

  const EventChange = (e) => {
   
    setOtherValue({ ...otherValue, [e.target.id]: e.target.value });
    const errorMessage = ValidateField(e.target.id, e.target.value,otherValue?.Proposed_Event_Date,otherValue.Location ,otherValue?.Expected_Number_of_Student_Attendees,otherValue?.Expected_Number_of_External_Guests);
  
    setFormErrors({
      ...formErrors,
      [e.target.id]: errorMessage,
    });
  
   
   
  };

  const navigate=useNavigate();
 
  // const handleOtherValueChange = (e) => {
  //   setLoginState({
  //     ...loginState,
  //     [e.target.id]: e.target.value,
  //   });
  // };
 
 


 
  
  const handleClick = (e) => {
  
    e.preventDefault();
    otherValue.Event_Register_Date=new Date();
    const combinedArray = {...otherValue,...state};
   
    const {
      Event_Title,
      Event_Description,
      Proposed_Event_Date,
      Originating_Department,
      Location,
      Proposed_Event_Start_Time

    } = combinedArray;

    if(formErrors?.Proposed_Event_Date)
    {
      alert.error("Error : Missed deadline  ");
    }

    if (Event_Title=="" || Event_Description=="" || Proposed_Event_Date=="" || Location=="" || Proposed_Event_Start_Time==""

  
    ) {
    
      alert.error("please fill all the required field");
    } else{
      axios
        .post("https://api-v2.mastersunion.in/api/v1/event", combinedArray)
        .then((response) => {
          // Handle the response data
          setSuccess(response.data);


        })
        .catch((error) => {
          // Handle any errors
          setError(error.data);
        });
        navigate("/event-manage")
    }

    

  };

 
  useEffect(() => {
    if (success) {
      alert.success(success.message);
    }
    if (error) {
      alert.error(error.message);
    }
  }, [success, error]);
  return (
    <form className=" md:p-4 justify-center bg md:container md:mx-auto " >
      
      <div className="  grid grid-rows-2 md:grid-cols-2 gap-4 ">
     
      </div>
      <div className=" grid md:grid-cols grid-rows  ">
      <p class="text-4xl font-bold text-center text-gray-600 rounded-lg p-6 shadow-lg  m-4 transition-transform transform hover:scale-x-90 hover:scale-y-90  py-4">
  Event Registration Form
</p>

        {extrafields.map((field) => (

          <> 
  {field?.category &&             <div class="flex  items-center justify-center bg-gray-200 text-black rounded-lg p-6 shadow-lg m-4 transition-transform transform hover:scale-x-90 hover:scale-y-90 ">
  <span class="text-center text-xl font-bold text-blue">{field?.category}</span>
</div>}
          
            <Plusinput
          key={field.id}
          EventChange={EventChange}
          handledropdowndata={handledropdowndata}
          othervalue={otherValue[field.id]}
          labelText={field.labelText}
          labelFor={field.labelFor}
          id={field.id}
          name={field.name}
          type={field.type}
          value={field.value}
          category={field.category}
          isRequired={field.isRequired}
          placeholder={field.placeholder}
          options={field.options}
          // selectedValue={selectedValue}
          formErrors={formErrors[field.id]}
          subtext={field.subtext}
          formErrorsobj={formErrors}
        /></>
       
        ))}
      </div>
      <div className="flex flex-wrap mt-4">
        {" "}
        <button  type="button" onClick={handleClick}
  className="bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-indigo-600 hover:to-purple-500 focus:outline-none focus:ring focus:ring-indigo-300 text-white text-sm font-semibold py-2 px-6 rounded-md shadow-md"
  
>
  Register Event
</button>

      </div>
    </form>
  );
};

{
}
