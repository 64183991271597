import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ValidateField from "./ValidateField";

export function trimAndJoinString(inputString) {
  // Split the inputString by underscores and remove any empty strings
  const wordsArray = inputString
    .split("_")
    .filter((word) => word.trim() !== "");

  // Join the words back together
  const resultString = wordsArray.join(" ");

  return resultString;
}
function trimDateFromString(dateTimeString) {
  // Get only the date part (first 10 characters) from the dateTimeString

  const datePart = dateTimeString.substring(0, 10);


  return datePart;
}

export const Showevent = ({ Data }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const obj = Data?.user?.Event_Register_Date;
  const convertToCSV = (dataObject) => {
    const csvRows = [];
    const headers = Object.keys(dataObject);
    
    // Add headers to CSV
    csvRows.push(headers.join(','));

    // Add data rows to CSV
    const values = headers.map(header => dataObject[header]);
    csvRows.push(values.join(','));

    // Combine rows into a single CSV string
    const csvString = csvRows.join('\n');

    return csvString;
  };
  
  const downloadCSV = () => {
    const csvData = convertToCSV(Data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'user_data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // // Fetching the values of "Event_Register_Date" and "Any_other_requirements"

  // const anyOtherRequirements = getProperty("Any_other_requirements");

  var data;
  if (Data) {
    data = Data;
  }


  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Event doc`,
    onAfterPrint: () => {
      alert("Print Successful!");
      navigate("/");
    },
  });
  if (!data) {
    return <div>Loading...</div>; // Or any other loading indicator you prefer
  }

  // setEvent(Data);

  return (
    <div className="bg-gray-200 ">
     <div className="flex items-center justify-center"> <h2 className="text-2xl font-bold mb-4 ">Event Details</h2></div>
      <div className="flex items-center justify-center gap-4">
        {" "}
       
        <button
          onClick={handleprint}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600  "
        >
          DOWNLOAD PDF
        </button>
             <button  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={downloadCSV}>Download CSV</button>
      </div>
      <main className="container" ref={componentRef}>
        <div className="max-w-4xl mx-auto p-4">
       
          <div className="grid grid-cols md:grid-cols gap-4">
            {Object.entries(data).map(([key, value]) => {
              <h2 className="font-medium text-lg mb-2 text-gray-800">{key}</h2>;

              if (value === "") {
                return null;
              }

              const errorMessage = ValidateField(
                key,
                value,
                data?.Proposed_Event_Date,
                Data?.Event_Register_Date
              );
             
              return (
                <>

                  {!Array.isArray(value) ? (
                    <>
                      {" "}
                      
                      <div
                        key={key}
                        className="border rounded-lg p-4 bg-white shadow-md hover:shadow-lg  "
                      >
                        <h2 className="font-medium text-lg mb-2 text-gray-800">
                          {key === "PhotographyandVideography"
                            ? "Photography and Videography"
                            : trimAndJoinString(key)}
                        </h2>
                        {typeof value === "object" ? (
                          <pre className="bg-gray-100 p-2">{value?.value}</pre>
                        ) : (
                          <div className=" p-2 h-9 text-gray-600 font-sm whitespace-pre-wrap break-words h-full">
                            {value}
                            {errorMessage && (
                              <p className="text-red-500 text-sm mt-2">
                                {errorMessage}
                              </p>
                            )}
                          </div>
                        )}

                        {/* {key == "Location" ? (
                          <>
                            {" "}
                            {value?.map((item) => {
                              return (
                                <>
                                  {" "}
                                  <div key={key} className="">
                                    <p className="bg-gray-100 p-2">
                                      {item?.value}
                                    </p>

                            </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </>
                  ) : key == "Location" ||
                    key == "Internal" ||
                    key === "Meals_required" || key==="Sections_open_for" ? (
                    <>
                      {" "}
                      {
                        <div>
                          {" "}
                          <h2 className="font-medium text-lg mb-2 text-gray-800">
                            {value[0]?.value ? trimAndJoinString(key) : ""}
                          </h2>{" "}
                          {value?.map((item) => {
                            return (
                              <>
                                {item?.value ? (
                                  <div
                                    key={key}
                                    className="border rounded-lg p-4 bg-white shadow-md hover:shadow-lg  "
                                  >
                                   
                                    <div className="bg-gray-100 p-2 ">
                                      {item.value ? item?.value : ""}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </div>
                      }
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>

        
        </div>
      </main>
      {/* <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={toggleOpen}
          >
            {isOpen ? "Hide JSON" : "Show JSON"}
          </button>
          {isOpen && (
            <div className="border rounded-lg mt-4 p-4">
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
          )} */}

    </div>
  );
};
