export const checklabel = (labelText) => {
  if (
    labelText != "Total Number of Attendees" &&
    labelText != "Equipment/ Technology" &&
    labelText != "Seating" &&
    labelText != "Special Requirement" &&
    labelText != "Photography and Videography" &&
    labelText != "Special Guests or Performers" &&
    labelText != "Standees" &&
    labelText != "Logistics" &&
    labelText != "Food Category" &&
    labelText != "Food Preferences & Cutlery" &&
    labelText != "Tier" &&
    labelText != "Budget" &&
    labelText != "Approval Required" &&
    labelText != "Inclusions" &&
    labelText != "Special Gifting" &&
    labelText != "Team Name" &&
    labelText != "Requestor Name" &&
    labelText != "Requestor Phone Number" &&
    labelText != "Requestor Email" &&
    labelText != "SPOC from Requestor's Team" &&
    labelText != "Event Title" &&
    labelText != "Event Description" &&
    labelText != "Proposed Event Date" &&
    labelText != "Proposed Event Start Time" &&
    labelText != "Proposed Event End Time" &&
    labelText != "Schedule" &&
    labelText != "Please specify the event location" &&
    labelText != "Event Register Date" &&
    labelText != "Event Day Type" &&
    labelText != "Is there any requirement for a student moderator?" &&
    labelText != "Sections open for" &&
    labelText != "Please provide the image link for the silver frame (if any)." &&
    labelText !=
      "Please specify if you need Auditorium (Once confirmed, it can not be cancelled)" &&
    labelText !==
      "Please confirm the catering quality that you would need. This should be in line with your guest tier!" &&
    labelText !=
      "Please select all meals that you would need to be arranged for your guest" &&
    labelText !=
      "If you have any marketing requirements, please mention the same." && labelText!="Is there any additional information or specific requests you would like to share regarding the event?"
  ) {
    
    return true;
  } else {
    return false;
  }
};
export function removeSpacesAndJoin(str) {
  // Split the string into an array of characters
  const charArray = str.split("");

  // Filter out the spaces from the array
  const charArrayWithoutSpaces = charArray.filter((char) => char !== " ");

  // Join the characters back into a single string
  const result = charArrayWithoutSpaces.join("");

  return result;
}
