import React, { useEffect, useState } from "react";
import EditableInput from "./EditableInput";
import { SaveIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ValidateField from "./ValidateField";

const EditEvent = ({ Editdata }) => {
  const [data, setData] = useState({});
  const [fieldsState, setFieldState] = useState({});
  const [fields, setfields] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://api-v2.mastersunion.in/api/v1/getsingleevent?id=${Editdata}`)
      .then((response) => {
        // Handle the response data
        setData(response.data.user);
      })
      .catch((error) => {
        // Handle any errors
        // console.log(error.data, "error");
      });
  }, [Editdata]);

  Object.keys(fields).forEach((key) => {
    fieldsState[key] = null;
  });
  const Saveeditevent = () => {
    axios
      .patch("https://api-v2.mastersunion.in/api/v1/event/editbyId", data)
      .then((response) => {
        // Handle the response data
        setData(response.data.user);
      })
      .catch((error) => {
        // Handle any errors
        console.log(error.data, "error");
      });
    navigate("/");
  };
  const handleInputChange = (label, newValue) => {
    setData((fieldsState) => ({
      ...fieldsState,
      [label]: newValue,
    }));
  };

  var errorMessage
  return (
    <>
      <div className="grid md:grid-cols-3 gap-2  grid-col min-w-full bg-gray-200 p-6  border border-gray-300 p-4">
        {data &&
          Object.entries(data).map(([key, value]) => (
            
        
            <>
               
                 
                {Array.isArray(value)? "" : key!="_id" ?  <EditableInput
                key={key}
                label={key}
                value={value}
                onChange={(newValue, key) => handleInputChange(newValue, key)}
                error={ ValidateField(key, value,data?.Proposed_Event_Date,data?.Event_Register_Date)}
              /> :""} 

            </>
            
          ))}
        <div className="mr-50">
          <button
            onClick={Saveeditevent}
            className=" items-center w-24 h-15 justify-center px-4 py-2 space-x-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          >
            {/* <SaveIcon className="w-5 h-5" /> */}
            <span className="">Save</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default EditEvent;
